import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Text } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import logo from "../assets/image/png/logo.png";

const BoxStyled = styled(Box)`
  background-color: ${({ theme }) => theme.colors.background};
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default function OauthPage() {
  return (
    <PageWrapper>
      <BoxStyled>
        <Box>
          <Container className="text-center mb-5">
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <Box className="text-center">
                  <img src={logo} alt="Logo" width="80"></img>
                </Box>
              </Col>
            </Row>
          </Container>
        </Box>
        <Container className="text-center mb-10">
          <Row className="justify-content-center">
            <Col lg="10" xl="8">
              <Text color="light" fontSize={4}>
                Almost there!
                <br />
                <Text color="lightShade" lineHeight={1.2} mt={3}>
                  Please navigate back to the Allo app to finish linking.
                </Text>
              </Text>
            </Col>
          </Row>
        </Container>
      </BoxStyled>
    </PageWrapper>
  );
}
